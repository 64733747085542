import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Button, Stack, Container, Dialog, DialogTitle, CardMedia, Card, CardActions, Typography, DialogContent } from '@mui/material';
import { WhatsApp, MenuBook, LocationOn, CalendarMonth, Description, LiveTv, Instagram, Star } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const App = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();

  const [dialogo, setDialogo] = useState(false);
  const [texto, setTexto] = useState("");
  const [titulo, setTitulo] = useState("");
  const [cargado, setCargado] = useState(false);

  const [promociones, setPromociones] = useState("");

  const abrirDialogo = (texto, titulo) => {
    setDialogo(true);
    setTexto(texto);
    setTitulo(titulo);
  }

  const cerrarDialogo = () => {
    setDialogo(false);
  }

  const cargarPromociones = async () => {
    try {
      const peticion = await axios.get("https://toshiro.cl/toshirojs/promociones");
      const resultado = await peticion.data;
      setPromociones(resultado);
    } catch (e) {
      console.log(e);
    }
  }

  const dioClick = async (x) => {
    try {
      const peticion = await axios.post("https://toshiro.cl/toshirojs/click", { desde: x });
      const respuesta = await peticion.data;
    } catch (e) {
      console.log(e);
    }
  }

  function Contenido() {
    return (
      <>
        <Stack direction={'column'} alignItems={'center'} >
          <img src='./promo/logov9.png' width={'50%'} alt="Logo Toshiro Sushi" />
          {/* <img src='https://toshiro.cl/promo/barrawebv2.png' width={'20%'} /> */}
        </Stack>
        <Stack direction={'column'} alignItems={'center'} marginTop={4} spacing={1} >
          <Button LinkComponent={Link} to="./carta" onClick={() => dioClick("mcarta")} target={"_blank"} size='large' style={{ width: '100%', fontSize: 19 }} variant="contained" color='warning' startIcon={<MenuBook />}>
            Carta
          </Button>
          <Button LinkComponent={Link} to="https://wa.me/message/GN6PAMCXSWHUP1" onClick={() => dioClick("mwsp")} target={"_blank"} size='large' style={{ width: '100%', fontSize: 19 }} variant="contained" color='success' startIcon={<WhatsApp />}>
            WhatsApp
          </Button>
          <Button LinkComponent={Link} to="https://instagram.com/toshirosushicl" onClick={() => dioClick("mig")} target={"_blank"} size='large' style={{ width: '100%', fontSize: 19 }} variant="contained" color='secondary' startIcon={<Instagram />}>
            Instagram
          </Button>
          <Button LinkComponent={Link} to="https://goo.gl/maps/jLpSVrQpesNs1heB9" onClick={() => dioClick("mmapa")} target={"_blank"} size='large' style={{ width: '100%', fontSize: 19 }} variant="contained" color='info' startIcon={<LocationOn />}>
            Ubicación
          </Button>
        </Stack>
      </>
    )
  }

  useEffect(() => {
    cargarPromociones();
  }, [])

  if (promociones.length === 0) {
    return (
      <Container maxWidth="sm" style={{ marginTop: 30 }}>
        <Contenido />
      </Container>
    )
  } else if (promociones[0].id === 0) {
    return (
      <Container maxWidth="sm" style={{ marginTop: 30 }}>
        <Contenido />
      </Container>
    )
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: 30 }}>
      <Contenido />
      <hr color='#343434' style={{ marginTop: 50, marginBottom: 10, height: 0.5 }} />
      <Stack direction={'column'} alignItems={'center'} marginTop={1} marginBottom={3} spacing={2} >
        {promociones.map((e) => (
          <Card style={{ minWidth: '100%' }} key={e.id}>
            <CardMedia>
              <img width={"100%"} src={e.imagen} alt={e.nombre} />
            </CardMedia>
            <CardActions>
              {e.fecha !== 0 &&
                <Button size="small" style={{ textTransform: 'none', color: 'black' }} startIcon={<CalendarMonth />}>{e.fecha_texto}</Button>
              }
              {e.condiciones !== 0 &&
                <Button size="small" style={{ textTransform: 'none', color: 'black' }} startIcon={<Description />} onClick={() => abrirDialogo(e.condiciones_texto, e.nombre)}>Condiciones</Button>
              }
              {e.transmision !== 0 &&
                <Button size="small" style={{ textTransform: 'none', color: 'black' }} startIcon={<LiveTv />}>Transmisión en vivo</Button>
              }
              {e.carta !== 0 &&
                <Button LinkComponent={Link} to="./carta" size="small" style={{ textTransform: 'none', color: 'black' }} startIcon={<MenuBook />}>Ver la Carta</Button>
              }
              {e.whatsapp !== 0 &&
                <Button LinkComponent={Link} to={"https://api.whatsapp.com/send?phone=" + e.whatsapp_numero + '&text=Hola, vi la promoción "' + e.nombre + '" y quiero hacerla valida!'} size="small" style={{ textTransform: 'none', color: 'black' }} startIcon={<WhatsApp />}>Yo quiero!</Button>
              }
              {e.personalizado !== 0 &&
                <Button LinkComponent={Link} to={e.personalizado_enlace} size="small" style={{ textTransform: 'none', color: 'black' }} startIcon={<Star />}>{e.personalizado_texto}</Button>
              }
            </CardActions>
          </Card>
        ))}

        <Dialog minWidth={"lg"} style={{ minWidth: '100%' }} open={dialogo} onClose={() => cerrarDialogo()}>
          <DialogTitle style={{ flex: 1 }} maxWidth={'100%'} flex={1}>Promo: {titulo}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <div dangerouslySetInnerHTML={{ __html: texto }} />
            </Typography>
          </DialogContent>
        </Dialog>

      </Stack>
    </Container>
  )
}

export default App;
